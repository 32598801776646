<template>
  <v-card flat class="mt-5">
    <v-form ref="form" lazy-validation class="multi-col-validation mt-6">
      <div class="px-3">
        <v-card-text class="pt-5">
          <v-row>
            <v-col cols="12" sm="8" md="6">
              <v-text-field
                v-model="currPassword"
                color="#137547"
                outlined
                dense
                label="Trenutna lozinka"
                :type="show1 ? 'text' : 'password'"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="show1 = !show1"
                :rules="passwordRules"
                required
              ></v-text-field>
              <v-text-field
                v-model="password"
                color="#137547"
                outlined
                dense
                label="Nova lozinka"
                :type="show2 ? 'text' : 'password'"
                :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="show2 = !show2"
                :rules="passwordRules"
                required
              ></v-text-field>
              <v-text-field
                v-model="repeatedPassword"
                color="#137547"
                dense
                outlined
                label="Ponovite lozinku"
                :type="show3 ? 'text' : 'password'"
                :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="show3 = !show3"
                :rules="repeatedPasswordRules"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </div>
      <div v-if="this.response === 400">
        <v-card-text> Vaša lozinka nije promenjena, došlo je do greške. </v-card-text>
        <v-card-text>
          <v-btn color="secondary" outlined class="mt-3" @click.prevent="reset"> Pokušaj ponovo </v-btn>
        </v-card-text>
      </div>
      <v-divider></v-divider>
      <div class="pa-3">
        <v-card-text>
          <v-btn color="success" class="me-3 mt-3" @click.prevent="changePass"> Sačuvaj </v-btn>
          <v-btn color="secondary" outlined class="mt-3" @click.prevent="reset"> Poništi </v-btn>
        </v-card-text>
      </div>
      <v-snackbar v-model="snackbar" color="green" bottom> Vaša lozinka je uspešno promenjena.</v-snackbar>
    </v-form>
  </v-card>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      currPassword: "",
      password: "",
      repeatedPassword: "",
      response: 0,
      show1: false,
      show2: false,
      show3: false,
      passwordRules: [(v) => !!v || "Lozinka je obavezna"],
      repeatedPasswordRules: [(v) => !!v || "Lozinka je obavezna", (v) => v === this.password || "Lozinke se ne poklapaju"],
      snackbar: false,
    };
  },
  methods: {
    ...mapActions(["LogOut"]),
    async logOut() {
      await this.LogOut();
      this.$router.push("/pocetna");
    },
    async changePass() {
      if (this.$refs.form.validate()) {
        let passwordForm = {
          oldPassword: this.currPassword,
          newPassword: this.password,
        };
        await axios
          .post("User/ChangePassword", passwordForm)
          .then((response) => {
            localStorage.setItem("jwtToken", response.data.jwt);
            this.snackbar = true;
            setTimeout(() => {
              this.snackbar = false;
            }, 3000);
          })
          .catch(() => {
            this.response = 400;
          });
      }
    },
    reset() {
      this.currPassword = "";
      this.password = "";
      this.repeatedPassword = "";
      this.response = 0;
      this.show2 = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
